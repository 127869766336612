"use client";

import {ContentModeType} from "publit-shared";
import {useTrack} from "lib/analytics/useTrack";

export default function Analytics({
  mode,
  itemID,
}: {
  mode: ContentModeType;
  itemID?: string;
}) {
  useTrack({isPreview: mode === "live-preview", itemID});

  return <div className="hidden" hidden data-analytics />;
}
