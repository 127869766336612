"use client";

import {ChangeEventHandler, ReactNode} from "react";
import {Search} from "lucide-react";

import {DirectoryCategoryDataType, DirectoryContentType} from "publit-shared";
import {useParams, useSearchParams} from "next/navigation";
import {cn} from "bits/utils";
import {LogoImage} from "../Images";

export default function DirectoryFilterBar({
  directory,
  categories,
  hideClearButton,
  autoFocus,
}: {
  directory: DirectoryContentType;
  categories: DirectoryCategoryDataType;
  hideClearButton?: boolean;
  autoFocus?: boolean;
}) {
  const sp = useSearchParams();

  const searchQuery = sp?.get("query") ?? "";

  return (
    <div className={cn("py-8 bg-custom/10 border-b", "md:py-10")}>
      <div
        className={cn(
          "container mx-auto items-center gap-8 px-8",
          "sm:flex",
          "md:px-10"
        )}
      >
        <LogoImage
          directory={directory}
          size="medium"
          className="shrink-0 hidden sm:block"
        />
        <form className="flex flex-col w-full gap-8" action="/search">
          <FilterSection
            title="Filter by Categories"
            accessory={
              !hideClearButton && (
                <a
                  href={`/search${searchQuery ? `?query=${searchQuery}` : ""}`}
                  className="font-semibold text-custom"
                >
                  Clear Filters
                </a>
              )
            }
          >
            <div className="flex flex-col gap-2.5 sm:flex-row">
              <CategorySelect
                name="group"
                overrideURLParamKey="groupSlug"
                title={directory.info.groupTitle ?? "Group"}
                items={categories.group}
              />
              {directory.info.fieldStructure.fieldOrder.map(field => (
                <CategorySelect
                  key={field}
                  name={`customField${field}`}
                  title={
                    directory.info.fieldStructure[`field${field}Title`] ??
                    "Custom Field"
                  }
                  items={categories[`customField${field}`]}
                />
              ))}
            </div>
          </FilterSection>
          <FilterSection title="Search">
            <div className="flex items-stretch w-full">
              <input
                className="py-2.5 px-4 w-full rounded-lg rounded-r-none border-none outline-none ring-1 ring-custom/40 focus:ring-custom/70"
                placeholder="e.g. name, college, year"
                name="query"
                defaultValue={searchQuery}
                autoFocus={autoFocus && searchQuery.trim().length === 0}
              />
              <button
                className="px-3 bg-custom text-white rounded-lg rounded-l-none ring-1 ring-custom"
                type="submit"
              >
                <Search size={20} />
              </button>
            </div>
          </FilterSection>
        </form>
      </div>
    </div>
  );
}

function FilterSection(props: {
  title: string;
  accessory?: ReactNode;
  children: ReactNode;
}) {
  return (
    <div className="flex flex-col gap-2.5">
      <header className="flex justify-between">
        <p className="font-semibold">{props.title}</p>
        {props.accessory}
      </header>
      {props.children}
    </div>
  );
}

function CategorySelect({
  name,
  title,
  items,
  overrideURLParamKey,
}: {
  name: string;
  title: string;
  items: {name?: string; value: string}[];
  overrideURLParamKey?: string;
}) {
  const sp = useSearchParams();
  const params = useParams();

  const defaultValue =
    sp?.get(name) ?? params?.[overrideURLParamKey ?? name] ?? "";

  const onChange: ChangeEventHandler<HTMLSelectElement> = e => {
    e.target.form?.submit();
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <select
      className="py-3 px-4 w-full bg-white rounded-lg border border-custom/40 appearance-none focus-visible:outline-none focus-visible:border-custom/70 focus-visible:ring-4 focus-visible:ring-custom/10"
      name={name}
      onChange={onChange}
      defaultValue={defaultValue}
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
        backgroundPosition: `right 0.5em center`,
        backgroundRepeat: `no-repeat`,
        backgroundSize: `1.5em 1.5em`,
      }}
    >
      <option value="">{title}</option>
      {items.map(item => (
        <option key={item.value} value={item.value}>
          {item.name ?? item.value}
        </option>
      ))}
    </select>
  );
}
